<template>
    <div class="order">
        <el-row class="pb">
            <el-select class="pr" v-model="payStateValue" @change="loadDataList" placeholder="请选择">
                <el-option v-for="item in payStateOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-select class="pr" v-if="payStateValue != 0" v-model="orderStateValue" @change="loadDataList" placeholder="请选择">
                <el-option v-for="item in orderStateOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-tag v-if="filterUser != ''" :key="filterUser" closable @close="handleClick('removeUserFilter')" type=""> 用户：{{ filterUser }} </el-tag>
            <el-tag v-if="filterWord != ''" :key="filterWord" closable @close="handleClick('removeWordFilter')" type=""> 关键词：{{ filterWord }} </el-tag>
            <el-button type="primary" @click="openSearch" icon="el-icon-search">搜索</el-button>
        </el-row>

        <el-table ref="mainTable" :data="dataList" style="width: 100%" stripe border>
            <el-table-column prop="outTradeNo" label="订单号" width="180" header-align="center"></el-table-column>
            <el-table-column prop="itemTitle" label="商品标题" header-align="center"></el-table-column>
            <el-table-column prop="price" label="价格" width="100" header-align="center" align="center" :formatter="formatter"></el-table-column>
            <el-table-column prop="itemAmount" label="数量" width="80" header-align="center" align="center"></el-table-column>
            <el-table-column prop="addressDetail" label="收货信息" header-align="center" :formatter="formatter"></el-table-column>
            <el-table-column prop="remark" label="买家备注" header-align="center"></el-table-column>
            <el-table-column prop="payState" label="支付状态" width="90" align="center" :formatter="formatter"></el-table-column>
            <el-table-column prop="orderState" label="订单状态" width="90" align="center" :formatter="formatter"></el-table-column>
            <el-table-column prop="note" label="物流单号" width="150" header-align="center" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180" header-align="center"></el-table-column>
            <el-table-column prop="payTime" label="支付时间" width="180" header-align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <p><el-button v-if="scope.row.orderState == 0 && scope.row.payState == 1" @click="handleClick('deliver', scope.row)" type="text" size="small">立即发货</el-button></p>
                    <p><el-button v-if="scope.row.orderState == 1 && scope.row.payState == 1 && scope.row.note =='' " @click="handleClick('editnote', scope.row)" type="text" size="small">填写物流单号</el-button></p>
                    <p><el-button v-if="scope.row.orderState == 1 && scope.row.payState == 1 && scope.row.note !='' " @click="handleClick('editnote', scope.row)" type="text" size="small">修改物流单号</el-button></p>
                    <p><el-button v-if="scope.row.orderState != -1" @click="handleClick('close', scope.row)" type="text" size="small">取消订单</el-button></p>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination v-if="totalCount > pageSize" class="pt" background layout="prev, pager, next" @current-change="changePage" :pager-size="pageSize" :total="totalCount"> </el-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageNo: 1,
            pageSize: 10,
            totalCount: 0,
            dataList: [],
            orderStateOptions: [
                {
                    value: "2",
                    label: "全部订单状态",
                },
                {
                    value: "0",
                    label: "待发货",
                },
                {
                    value: "1",
                    label: "已发货",
                },
            ],
            orderStateValue: "2",
            payStateOptions: [
                {
                    value: "2",
                    label: "全部支付状态",
                },
                {
                    value: "0",
                    label: "待支付",
                },
                {
                    value: "1",
                    label: "已支付",
                },
            ],
            payStateValue: "2",
            filterUserId: "0",
            filterUser: "",
            filterWord: "",
        };
    },
    mounted() {
        let userId = this.$route.query.userId;
        if (userId) {
            this.$http({
                url: "/sys/operation/getUserInfo",
                data: {
                    id: userId,
                },
            }).then((res) => {
                this.filterUser = res.data.nickname;
            });
        }
        this.loadDataList();
    },
    methods: {
        formatter(row, column, value) {
            if (column.property == "payState") {
                switch (value) {
                    case 0:
                        return "待支付";
                    case 1:
                        return "已支付";
                }
            } else if (column.property == "orderState") {
                switch (value) {
                    case 0:
                        return "待发货";
                    case 1:
                        return "已发货";
                    case -1:
                        return "已取消";
                }
            } else if (column.property == "price") {
                return "￥ " + value.toLocaleString();
            } else if (column.property == "addressDetail") {
                return row.addressFullName + "  " + row.addressMobile + "  " + row.addressDetail;
            }
            return value;
        },
        loadDataList: function() {
            this.$http({
                url: "/sys/order/list",
                data: {
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    payState: this.payStateValue,
                    orderState: this.orderStateValue,
                    userId: this.filterUserId,
                    keyword: this.filterWord,
                },
            }).then((res) => {
                this.totalCount = parseInt(res.data.total);
                this.dataList = res.data.records;
            });
        },
        handleClick: function(action, row) {
            // 标记发货
            if ("deliver" == action) {
                this.$confirm("是否要将订单标记为已经发货？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http({
                            url: "/sys/order/deliverOrder",
                            data: {
                                id: row.id,
                            },
                        }).then((res) => {
                            // 提示填写单号
                            this.editNote(row.id);
                        });
                    })
                    .catch(() => {});
            }
            // 填写物流单号
            if ("editnote" == action) {
                this.editNote(row.id);
            }
            // 关闭订单
            if ("close" == action) {
                let tips = row.payState == 1 ? "当前订单已支付，如果取消订单需要联系买家进行手动退款，确定要继续吗？" : "当前订单还未支付，确定要强制关闭订单吗？";
                this.$confirm(tips, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http({
                            url: "/sys/order/closeOrder",
                            data: {
                                id: row.id,
                            },
                        }).then((res) => {
                            this.loadDataList();
                        });
                    })
                    .catch(() => {});
            }
            // 移除筛选用户
            if ("removeUserFilter" == action) {
                this.filterUserId = "0";
                this.filterUser = "";
                this.loadDataList();
            }
            // 移除筛选搜索
            if ("removeWordFilter" == action) {
                this.filterWord = "";
                this.loadDataList();
            }
        },
        editNote: function(id) {
            // 填写物流信息
            this.$prompt("请填写物流信息，比如：顺丰快递 SF000001", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "稍后",
            })
                .then(({ value }) => {
                    this.$http({
                        url: "/sys/order/updateOrderNote",
                        data: {
                            id: id,
                            note: value,
                        },
                    }).then((res) => {
                        this.$message({
                            type: "success",
                            message: "保存成功!",
                        });
                        this.loadDataList();
                    });
                })
                .catch(() => {});
        },
        openSearch: function() {
            this.$prompt("请输入关键词 (订单号、作品标题、手机号码)", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(({ value }) => {
                    this.filterWord = value;
                    this.loadDataList();
                })
                .catch(() => {});
        },
        // 翻页
        changePage: function(value) {
            this.pageNo = value;
            this.loadDataList();
        },
    },
};
</script>
<style>
.el-tag {
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
}
</style>
